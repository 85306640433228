/* eslint-disable import/no-cycle */
/* eslint-disable no-use-before-define */
import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import { notification } from 'antd';
import moment from 'moment';
import API from '../services';

const initialState = {
  courses: [],
  course: {},
  courseModules: [],
  isLoading: false,
  announcements: [],
  messages: [],
  courseMaterials: [],
  collectedPoints: 0,
  availablePoints: 0,
  module: {},
  courseStudentMaterials: [],
  courseFaqs: [],
  courseLevels: [],
  courseFaqGroups: [],
  subjectPoints: [],
  negativePoints: [],
  surveys: [],
};

export const getCourses = createAsyncThunk('courses/getCourses', async () => {
  return API.CoursesService.getCourses();
});

export const addCourse = createAsyncThunk('courses/addCourse', async payload => {
  return API.CoursesService.addCourse(payload);
});

export const editCourse = createAsyncThunk('courses/editCourse', async payload => {
  return API.CoursesService.editCourse(payload);
});

export const assignLevelToCourse = createAsyncThunk('courses/assignLevelToCourse', async payload => {
  return API.CoursesService.assignLevelToCourse(payload);
});

export const assignStudentToCourse = createAsyncThunk('courses/assignStudentToCourse', async payload => {
  return API.CoursesService.assignStudentToCourse(payload);
});

export const assignMaterialsToCourse = createAsyncThunk('courses/assignMaterialsToCourse', async payload => {
  return API.CoursesService.assignMaterialsToCourse(payload);
});

export const getAnnouncementsByCourseId = createAsyncThunk('courses/getAnnouncementsByCourseId', async courseId => {
  return API.CoursesService.getAnnouncementsByCourseId(courseId);
});

export const addAnnouncement = createAsyncThunk('courses/addAnnouncement', async payload => {
  return API.CoursesService.addAnnouncement(payload);
});

export const addSurvey = createAsyncThunk('courses/addSurvey', async payload => {
  return API.CoursesService.addSurvey(payload);
});

export const getSurveysByCourseId = createAsyncThunk('courses/getSurveysByCourseId', async courseId => {
  return API.CoursesService.getSurveysByCourseId(courseId);
});

export const hideSurvey = createAsyncThunk('courses/hideSurvey', async surveyId => {
  return API.CoursesService.hideSurvey(surveyId);
});

export const unhideSurvey = createAsyncThunk('courses/unhideSurvey', async surveyId => {
  return API.CoursesService.unhideSurvey(surveyId);
});

export const addVote = createAsyncThunk('courses/addVote', async payload => {
  return API.CoursesService.addVote(payload);
});

export const addMessage = createAsyncThunk('courses/addMessage', async courseId => {
  return API.CoursesService.addMessage(courseId);
});

export const getCourse = createAsyncThunk('courses/getCourse', async courseId => {
  return API.CoursesService.getCourse(courseId);
});

export const getCourseModules = createAsyncThunk('courses/getCourseModules', async courseId => {
  return API.CoursesService.getCourseModules(courseId);
});

export const addModule = createAsyncThunk('courses/addModule', async payload => {
  return API.CoursesService.addModule(payload);
});

export const getModule = createAsyncThunk('courses/getModule', async moduleId => {
  return API.CoursesService.getModule(moduleId);
});

export const addModuleRequirement = createAsyncThunk('courses/addModuleRequirement', async payload => {
  return API.CoursesService.addModuleRequirement(payload);
});

export const addSubmessage = createAsyncThunk('courses/addSubmessage', async (payload, thunkApi) => {
  const addSubmessageResult = await API.CoursesService.addSubmessage(payload);
  thunkApi.dispatch(
    addSubmessageToPost({
      message: addSubmessageResult.data,
      messageId: payload.messageId,
    }),
  );
  return addSubmessageResult;
});

export const getCourseMaterials = createAsyncThunk('students/getCourseMaterials', async courseId => {
  return API.CoursesService.getCourseMaterials(courseId);
});

export const unlockFreeMaterial = createAsyncThunk('student/courses/user/details', async payload => {
  return API.StudentsService.unlockFreeMaterial(payload);
});

export const assignSubmessagesToMessage = createAsyncThunk('courses/assignSubmessages', async (messageId, thunkApi) => {
  const gettingSubmessages = await API.CoursesService.getSubmessagesByMessageId(messageId);
  thunkApi.dispatch(
    getSubmessagesFromMessage({
      messageId,
      submessages: gettingSubmessages.data,
    }),
  );
});

export const getStudentsPoints = createAsyncThunk('points/getStudentsPoints', async payload => {
  return API.RewardsService.getStudentsPoints(payload);
});

export const editModal = createAsyncThunk('courses/editModal', async payload => {
  return API.CoursesService.editModule(payload);
});

export const removeRequirement = createAsyncThunk('courses/removeRequirement', async requirementId => {
  return API.CoursesService.removeRequirement(requirementId);
});

export const getCourseFaqs = createAsyncThunk('courses/getCourseFaqs', async ({ courseId, page, limit, search }) => {
  return API.CoursesService.getCourseFaqs({ courseId, page, limit, search });
});

export const addFaq = createAsyncThunk('courses/addFaq', async ({ courseId, payload }) => {
  return API.CoursesService.addFaq(courseId, payload);
});

export const editFaq = createAsyncThunk('courses/editFaq', async ({ faqId, courseId, payload }) => {
  return API.CoursesService.editFaq(faqId, courseId, payload);
});

export const getCourseLevels = createAsyncThunk('courses/getCourseLevels', async courseId => {
  return API.CoursesService.getCourseLevels(courseId);
});

export const getCourseFaqGrups = createAsyncThunk('courses/getCourseFaqGroups', async courseId => {
  return API.CoursesService.getCourseFaqGroups(courseId);
});

export const addFaqGroup = createAsyncThunk('courses/addFaqGroup', async ({ courseId, payload }) => {
  return API.CoursesService.addFaqGroup(courseId, payload);
});

export const editFaqGroup = createAsyncThunk('courses/editFaqGroup', async ({ faqGroupId, courseId, payload }) => {
  return API.CoursesService.editFaqGroup(faqGroupId, courseId, payload);
});

export const changeStudentCourse = createAsyncThunk('courses/changeStudentCourse', async payload => {
  return API.CoursesService.changeStudentCourse(payload);
});

export const getCourseNegativePoints = createAsyncThunk(
  'courses/getCourseNegativePoints',
  async ({ courseId, page, limit, search }) => {
    return API.CoursesService.getCourseNegativePoints(courseId, page, limit, search);
  },
);

export const getAllCourseNegativePoints = createAsyncThunk('courses/getAllCourseNegativePoints', async courseId => {
  return API.CoursesService.getAllCourseNegativePoints(courseId);
});

export const assignNegativePoints = createAsyncThunk('courses/assignNegativePoints', async payload => {
  return API.CoursesService.assignNegativePoints(payload);
});
export const addCourseNegativePoints = createAsyncThunk('courses/addCourseNegativePoints', async payload => {
  return API.CoursesService.addCourseNegativePoints(payload);
});

export const editCourseNegativePoints = createAsyncThunk('courses/editCourseNegativePoints', async payload => {
  return API.CoursesService.editCourseNegativePoints(payload);
});

const coursesSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    addSubmessageToPost: (state, action) => {
      const { messages } = state;
      const newMessages = messages.map(message => {
        if (message._id === action.payload.messageId) {
          message.submessages.push(action.payload.message);
        }
        return message;
      });
      state.messages = newMessages;
    },
    getSubmessagesFromMessage: (state, action) => {
      const { messages } = current(state);
      const newMessages = messages.map(message => {
        if (message._id === action.payload.messageId) {
          return {
            ...message,
            submessages: action.payload.submessages,
          };
        }
        return message;
      });
      state.messages = newMessages;
    },
  },
  extraReducers: {
    [getCourses.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getCourses.rejected.toString()]: state => {
      state.isLoading = true;
    },
    [getCourses.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.courses = action.payload.data;
    },
    [assignMaterialsToCourse.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się przypisać materiałów!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [assignMaterialsToCourse.fulfilled.toString()]: () => {
      notification.success({
        message: `Przypisano materiały do kursu!`,
        description: 'Materiały zostały pomyślnie przypisane do kursu.',
        placement: 'bottomRight',
      });
    },
    [assignStudentToCourse.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się przypisać studenta!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [assignStudentToCourse.fulfilled.toString()]: () => {},
    [assignLevelToCourse.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się przypisać poziomu!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [assignLevelToCourse.fulfilled.toString()]: () => {
      notification.success({
        message: `Przypisano poziom do kursu!`,
        description: 'Poziom został pomyślnie przypisany do kursu.',
        placement: 'bottomRight',
      });
    },
    [addCourse.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się dodać kursu!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [addCourse.fulfilled.toString()]: (state, action) => {
      notification.success({
        message: `Dodano nowy kurs ${action.payload.data.name}!`,
        description: 'Kurs został pomyślnie dodany.',
        placement: 'bottomRight',
      });
    },
    [editCourse.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się edytować kursu!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [editCourse.fulfilled.toString()]: (state, action) => {
      notification.success({
        message: `Edytowano kurs!`,
        description: 'Kurs został pomyślnie edytowany.',
        placement: 'bottomRight',
      });
    },
    [getAnnouncementsByCourseId.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getAnnouncementsByCourseId.rejected.toString()]: state => {
      state.isLoading = true;
    },
    [getAnnouncementsByCourseId.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.announcements = action.payload.data.sort((a, b) => {
        return moment(a.createdAt).isBefore(moment(b.createdAt)) ? 1 : -1;
      });
    },
    [addAnnouncement.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się dodać ogłoszenia!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [addAnnouncement.fulfilled.toString()]: () => {
      notification.success({
        message: `Dodano nowy ogłoszenie!`,
        description: 'Ogłoszenie zostało pomyślnie dodane.',
        placement: 'bottomRight',
      });
    },
    [addMessage.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się dodać komentarza!',
        description: `${
          action.error.message === 'Request failed with status code 400'
            ? 'Tylko aktywni kursanci mogą pisać na chacie'
            : action.error.message
        }`,
        placement: 'bottomRight',
      });
    },
    [addMessage.fulfilled.toString()]: () => {
      notification.success({
        message: `Dodano nowy komentarz!`,
        description: 'Komentarz został pomyślnie dodany.',
        placement: 'bottomRight',
      });
    },
    [addSubmessage.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się dodać komentarza!',
        description: `${
          action.error.message === 'Request failed with status code 400'
            ? 'Tylko aktywni kursanci mogą pisać na chacie'
            : action.error.message
        }`,
        placement: 'bottomRight',
      });
    },
    [addSubmessage.fulfilled.toString()]: () => {
      notification.success({
        message: `Dodano nowy komentarz!`,
        description: 'Komentarz został pomyślnie dodany.',
        placement: 'bottomRight',
      });
    },
    [getCourseMaterials.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getCourseMaterials.rejected.toString()]: state => {
      state.isLoading = false;
    },
    [getCourseMaterials.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.courseMaterials = action.payload.data;
    },
    [unlockFreeMaterial.pending.toString()]: state => {
      state.isLoading = true;
    },
    [unlockFreeMaterial.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się odblokować materialu',
        description: `${
          action.error.message === 'User is already assigned to course'
            ? 'Uczeń jest już przypisany do materiału'
            : action.error.message
        }`,
        placement: 'bottomRight',
      });
    },
    [unlockFreeMaterial.fulfilled.toString()]: state => {
      state.isLoading = false;
      notification.success({
        message: 'Odblokowano materiał',
        placement: 'bottomRight',
      });
    },
    [getStudentsPoints.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getStudentsPoints.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się pobrać punktów',
        description: `${action.error.message}`,
        placement: 'bottomright',
      });
    },
    [getStudentsPoints.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.collectedPoints = action.payload.data.assignedPoints;
      state.availablePoints = action.payload.data.availablePoints;
    },
    [getCourseModules.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getCourseModules.rejected.toString()]: state => {
      state.isLoading = false;
    },
    [getCourseModules.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.courseModules = action.payload.data;
    },

    [getCourse.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getCourse.rejected.toString()]: state => {
      state.isLoading = false;
    },
    [getCourse.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.course = action.payload.data;
    },
    [addModule.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się dodać modułu!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [addModule.fulfilled.toString()]: () => {
      notification.success({
        message: `Dodano nowy moduł!`,
        description: 'Moduł został pomyślnie dodany.',
        placement: 'bottomRight',
      });
    },
    [addModuleRequirement.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się dodać wymagania!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [addModuleRequirement.fulfilled.toString()]: () => {
      notification.success({
        message: `Dodano nowe wymaganie!`,
        description: 'Wymaganie zostało pomyślnie dodane.',
        placement: 'bottomRight',
      });
    },
    [getModule.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getModule.rejected.toString()]: state => {
      state.isLoading = false;
    },
    [getModule.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.module = action.payload.data;
    },
    [getCourseFaqs.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getCourseFaqs.rejected.toString()]: state => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się pobrać FAQ',
        placement: 'bottomRight',
      });
    },
    [getCourseFaqs.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.courseFaqs = action.payload.data;
    },
    [addFaq.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się dodać FAQ!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [addFaq.fulfilled.toString()]: () => {
      notification.success({
        message: `Dodano nowe FAQ!`,
        description: 'FAQ zostało pomyślnie dodane.',
        placement: 'bottomRight',
      });
    },
    [editFaq.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się edytować FAQ!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [editFaq.fulfilled.toString()]: () => {
      notification.success({
        message: `Edytowano FAQ!`,
        description: 'FAQ zostało pomyślnie edytowane.',
        placement: 'bottomRight',
      });
    },
    [getCourseLevels.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getCourseLevels.rejected.toString()]: state => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się pobrać poziomów kursu!',
        placement: 'bottomRight',
      });
    },
    [getCourseLevels.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.courseLevels = action.payload.data;
    },
    [getCourseFaqGrups.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getCourseFaqGrups.rejected.toString()]: state => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się pobrać grup FAQ!',
        placement: 'bottomRight',
      });
    },
    [getCourseFaqGrups.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.courseFaqGroups = action.payload.data;
    },
    [addFaqGroup.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się dodać grupy FAQ!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [addFaqGroup.fulfilled.toString()]: () => {
      notification.success({
        message: `Dodano nową grupę FAQ!`,
        description: 'Grupa FAQ została pomyślnie dodana.',
        placement: 'bottomRight',
      });
    },
    [editFaqGroup.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się edytować grupy FAQ!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [editFaqGroup.fulfilled.toString()]: () => {
      notification.success({
        message: `Edytowano grupę FAQ!`,
        description: 'Grupa FAQ została pomyślnie edytowana.',
        placement: 'bottomRight',
      });
    },
    [changeStudentCourse.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się zmienić kursu!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [changeStudentCourse.fulfilled.toString()]: () => {
      notification.success({
        message: `Zmieniono kurs!`,
        description: 'Kurs został pomyślnie zmieniony.',
        placement: 'bottomRight',
      });
    },
    [editModal.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się edytować modułu!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [editModal.fulfilled.toString()]: () => {
      notification.success({
        message: `Edytowano moduł!`,
        description: 'Moduł został pomyślnie edytowany.',
        placement: 'bottomRight',
      });
    },
    [removeRequirement.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się usunąć wymagania!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [removeRequirement.fulfilled.toString()]: () => {
      notification.success({
        message: `Usunięto wymaganie!`,
        description: 'Wymaganie zostało pomyślnie usunięte.',
        placement: 'bottomRight',
      });
    },
    [getCourseNegativePoints.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getCourseNegativePoints.rejected.toString()]: state => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się pobrać punktów ujemnych',
        placement: 'bottomright',
      });
    },
    [getCourseNegativePoints.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.negativePoints = action.payload.data;
    },
    [addCourseNegativePoints.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się dodać punktów ujemnych!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [addCourseNegativePoints.fulfilled.toString()]: () => {
      notification.success({
        message: `Dodano punkty ujemne!`,
        description: 'Punkty ujemne zostały pomyślnie dodane.',
        placement: 'bottomRight',
      });
    },
    [editCourseNegativePoints.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się edytować punktów ujemnych!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [editCourseNegativePoints.fulfilled.toString()]: () => {
      notification.success({
        message: `Edytowano punkty ujemne!`,
        description: 'Punkty ujemne zostały pomyślnie edytowane.',
        placement: 'bottomRight',
      });
    },
    [getAllCourseNegativePoints.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getAllCourseNegativePoints.rejected.toString()]: state => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się pobrać punktów ujemnych',
        placement: 'bottomright',
      });
    },
    [getAllCourseNegativePoints.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.negativePoints = action.payload.data;
    },
    [addSurvey.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się dodać ankiety!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [addSurvey.fulfilled.toString()]: () => {
      notification.success({
        message: `Dodano nową ankietę!`,
        description: 'Ankietę została pomyślnie dodana.',
        placement: 'bottomRight',
      });
    },
    [getSurveysByCourseId.fulfilled.toString()]: (state, action) => {
      state.surveys = action.payload.data;
    },
    [addVote.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się dodać głosu!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [addVote.fulfilled.toString()]: () => {
      notification.success({
        message: `Dodano głos!`,
        description: 'Głos został pomyślnie dodany.',
        placement: 'bottomRight',
      });
    },
    [hideSurvey.fulfilled.toString()]: () => {
      notification.success({
        message: `Ukryto ankietę!`,
        description: 'Ankietę została pomyślnie ukryta.',
        placement: 'bottomRight',
      });
    },
    [hideSurvey.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się ukryć ankiety!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [unhideSurvey.fulfilled.toString()]: () => {
      notification.success({
        message: `Pokazano ankietę!`,
        description: 'Ankietę została pomyślnie pokazana.',
        placement: 'bottomRight',
      });
    },
    [unhideSurvey.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się pokazać ankiety!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
  },
});

export const { addSubmessageToPost, getSubmessagesFromMessage } = coursesSlice.actions;

export default coursesSlice.reducer;
