// eslint-disable-next-line import/no-cycle
import { APIService } from './ApiService';

export const getCalendlyInvitees = ({ page, limit, search, filter, filterCancelled, startDate, endDate }) =>
  APIService.get(
    `crm/calendly/invitees?page=${page}&limit=${limit}&search=${search}&filter=${filter}&filterCancelled=${filterCancelled}${
      startDate ? `&startDate=${startDate}` : ''
    }${endDate ? `&endDate=${endDate}` : ''}`,
  );

export const getNoPackagesCustomers = ({ page, limit, search, filter }) =>
  APIService.get(`crm/no-packages?page=${page}&limit=${limit}&search=${search}&filter=${filter}`);

export const getCustomer = email => APIService.get(`crm/calendly/customer/${email}`);

export const getActiveStudentActivities = email => APIService.get(`crm/customer/activity/${email}`);

export const getActiveUserOrders = userId => APIService.get(`orders/users/${userId}`);

export const getActiveUserShopOrders = email => APIService.get(`shop/orders/email/${email}`);

export const getActiveStudentTickets = userID => APIService.get(`tickets/user/${userID}`);

export const getModerationList = () => APIService.get('users/moderation');

export const addTask = task => APIService.post('crm/tasks', task);

export const editTask = task => APIService.put('crm/tasks', task);

export const getTasks = ({ page, limit, search, filter }) =>
  APIService.get(`crm/tasks?page=${page}&limit=${limit}&search=${search}&filter=${filter}`);

export const getUserTasks = ({ page, limit, search, filter }) =>
  APIService.get(`crm/tasks/assigned?page=${page}&limit=${limit}&search=${search}&filter=${filter}`);

export const addConversation = conversation => APIService.post('crm/conversations', conversation);

export const editConversation = ({ conversationId, conversation }) =>
  APIService.put(`crm/conversations/${conversationId}`, conversation);

export const addCustomerDetails = customer => APIService.post('crm/customer/details', customer);

export const getCustomerDetails = email => APIService.get(`crm/customer/details/${email}`);

export const getActiveStudentTasks = (email, filter) => APIService.get(`crm/tasks/${email}?filter=${filter}`);

export const getActiveStudentConversations = email => APIService.get(`crm/conversations/${email}`);

export const getActiveStudentCalendlyInvitees = email => APIService.get(`crm/calendly/invitees/${email}`);

export const generateRecommendationCode = userId =>
  APIService.post('coupons/recommendation-codes/generate', { userId });

export const getActiveStudentTokens = userId => APIService.get(`users/tokens/${userId}`);

export const blockUser = userId => APIService.post(`users/${userId}/block`);

export const unblockUser = userId => APIService.post(`users/${userId}/unblock`);

export const getActiveStudentActivityGrid = userId => APIService.get(`materials/users/${userId}/activity`);

export const getCalendlyStats = ({ startDate, endDate }) =>
  APIService.get(
    `crm/calendly/stats?${startDate ? `startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}`,
  );
